<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col md="6">
                    <b-form-group :label="$t('route')">
                        <ValidationProvider
                            name="transfer_places"
                            rules="required"
                            v-slot="{ valid, errors }"
                        >
                            <b-input-group class="mb-3">
                                <parameter-selectbox class="w-100" code="housing_airport_transfer_places" :public="true"
                                                     v-model="request_data.transfer_places"
                                                     :validate-error="errors[0]"/>
                            </b-input-group>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>

                <b-col md="6">
                    <ValidationProvider name="fee" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('fee')">
                            <b-input-group class="mb-3">
                                <b-form-input
                                    readonly
                                    v-model="fee"
                                >
                                </b-form-input>
                            </b-input-group>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>

                <b-col v-if="showSubmit">
                    <b-row>
                        <b-col md="6">
                            <b-form-group :label="$t('airport')">
                                <ValidationProvider
                                    name="airport"
                                    rules="required"
                                    v-slot="{ valid, errors }"
                                >
                                    <b-input-group class="mb-3">
                                        <parameter-selectbox class="w-100" code="housing_request_airports"
                                                             :public="true"
                                                             v-model="request_data.airport"
                                                             :validate-error="errors[0]"/>
                                    </b-input-group>
                                </ValidationProvider>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">
                            <ValidationProvider name="arrival_date" rules="required" v-slot="{ valid, errors }"
                                                v-if="showDate">
                                <b-form-group :label="$t('arrival_date')">
                                    <select-date :is-time="true" v-model="request_data.arrival_date"
                                                 :validation-error="errors[0]"
                                                 :time="true"/>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="departure_date" rules="required" v-slot="{ valid, errors }"
                                                v-else>
                                <b-form-group :label="$t('departure_date')">
                                    <select-date :is-time="true" v-model="request_data.departure_date"
                                                 :validation-error="errors[0]"
                                                 :time="true"/>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="6">
                            <b-form-group :label="$t('airline_name')">
                                <ValidationProvider
                                    name="airline_name"
                                    rules="required"
                                    v-slot="{ valid, errors }"
                                >
                                    <b-input-group class="mb-3">
                                        <b-form-input
                                            v-model="request_data.airline_name"
                                            :class="errors[0] ? 'is-invalid' : ''"
                                        >
                                        </b-form-input>
                                    </b-input-group>
                                    <b-form-invalid-feedback
                                        v-if="errors[0]"
                                        v-html="errors[0]"
                                        class="mb-2"
                                    ></b-form-invalid-feedback>
                                </ValidationProvider>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">
                            <b-form-group :label="$t('flight_code')">
                                <ValidationProvider
                                    name="flight_code"
                                    rules="required"
                                    v-slot="{ valid, errors }"
                                >
                                    <b-input-group class="mb-3">
                                        <b-form-input
                                            v-model="request_data.flight_code"
                                            :class="errors[0] ? 'is-invalid' : ''"
                                        >
                                        </b-form-input>
                                    </b-input-group>
                                    <b-form-invalid-feedback
                                        v-if="errors[0]"
                                        v-html="errors[0]"
                                        class="mb-2"
                                    ></b-form-invalid-feedback>
                                </ValidationProvider>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-col>

                <b-col cols="12" class="d-flex justify-content-center align-items-center">
                    <b-form-group class="mb-0">
                        <b-button variant="primary" block @click="save" :disabled="formLoading">
                            {{ $t('save').toUpperCase() }}
                        </b-button>
                    </b-form-group>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>

<script>
// Services
import StudentRequestService from '@/services/StudentRequestService'
import {EventBus} from "@/main";
import moment from "moment/moment";

export default {
    props: {
        data: {
            type: Object
        }
    },
    data() {
        return {
            formLoading: false,
            selected: null,
            showDate: false,
            showSubmit: false,
            request_data: {
                housing_bed_id: this.data.housing_bed_id,
                transfer_places: null,
                fee: null,
                airport: null,
                arrival_date: null,
                airline_name: null,
                flight_code: null,
            }
        }
    },
    watch: {
        'request_data.transfer_places'(value) {
            if (value != null) {
                this.showSubmit = true;
            } else {
                this.showSubmit = false;
            }
            if (value === 'from_airport_to_island_condos') {
                this.showDate = true;
            } else {
                this.showDate = false;
            }
        }
    },
    computed: {
        getReservationToken() {
            return this.$store.getters['roomReservationSystem/getReservationToken']
        },
        fee() {
            return this.currencyFormat(this.data.airport_pick_up_fee ? this.data.airport_pick_up_fee : 0)
        }
    },
    mounted() {
        EventBus.$on('approvedRequestForm', (event) => {
            if (event.constantCode === 'housing_request.bed_hold_request_text') {
                this.save()
            }
        })
    },
    methods: {
        async save() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid) {
                if (this.request_data.transfer_places === 'from_airport_to_island_condos') {
                    this.request_data.arrival_date = moment(this.request_data.arrival_date).format('MM/DD/YYYY HH:mm')
                } else {
                    this.request_data.departure_date = moment(this.request_data.departure_date).format('MM/DD/YYYY HH:mm')
                }
                const config = {
                    headers: {
                        Authorization: `Bearer ${this.getReservationToken}`,
                        'Content-Type': 'multipart/form-data'
                    },

                };
                const formData = new FormData();
                formData.append('request_data[housing_bed_id]', this.request_data.housing_bed_id);
                formData.append('request_data[transfer_places]', this.request_data.transfer_places);
                formData.append('request_data[fee]', this.request_data.fee);
                formData.append('request_data[airport]', this.request_data.airport);

                if (this.request_data.transfer_places === 'from_airport_to_island_condos') {
                    formData.append('request_data[arrival_date]', this.request_data.arrival_date);
                }else{
                    formData.append('request_data[departure_date]', this.request_data.departure_date);

                }

                formData.append('request_data[airline_name]', this.request_data.airline_name);
                formData.append('request_data[flight_code]', this.request_data.flight_code);
                formData.append('request_type', 'airport_transfer_request');
                formData.append('housing_reservation_id', this.data.id);
                if (this.request_data.file && this.request_data.file.length > 0) {
                    for (let i = 0; i < this.request_data.file.length; i++) {
                        formData.append(`request_data[files][${i}]`, this.request_data.file[i]);
                    }
                }
                StudentRequestService.studentRequest(formData, config)
                    .then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                        this.$router.push('/housing/request/list');
                    })
                    .catch(e => {
                        EventBus.$emit('formClearRequest')

                        this.showErrors(e, this.$refs.formModalValidate);
                    })
            }
        }
    }
}
</script>
