<template>
    <div>
        <component :is="!isStudent?'tenant-app-layout':'app-layout'">
                <template v-slot:header v-if="isStudent">
                    <Header :title="$t('my_room_reservations')"
                            :is-filter="false">
                    </Header>
                </template>
                <template v-slot:header-mobile v-if="isStudent">
                    <HeaderMobile :title="$t('my_room_reservations')"
                                  :is-filter="false">
                    </HeaderMobile>
                </template>
                <div class="p-3">
                    <h5>RESULT</h5>
                    <div v-if="!datatable.rows.length" class="d-flex justify-content-center align-items-center p-5">
                        <b-spinner variant="primary" label="Loading..."></b-spinner>
                        <span class="ml-2">Loading...</span>
                    </div>
                    <div v-else>
                        <b-row v-for="(reservation, index) in datatable.rows" :key="index" class="py-2">
                            <b-col cols="12">
                                <b-card style="overflow: hidden">
                                    <b-row>
                                        <b-col
                                            v-for="visibleKey in visibleKeys"
                                            :key="visibleKey"
                                            cols="12"
                                            md="6"
                                            class="d-flex align-items-center mb-2 mb-md-0"
                                            v-if="reservation[visibleKey]"
                                        >
                                            <i :class="getIconClass(visibleKey, reservation[visibleKey])" class="remix-icon mr-2"></i>
                                            <span>{{ reservation[visibleKey] }}</span>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="auto" class="mt-2">
                                            <b-button
                                                v-if="requestButtonRules(reservation)"
                                                variant="primary"
                                                class="mr-2"
                                                style="float: left"
                                                @click="requestFormShow(reservation)"
                                            >
                                                <i class="ri-add-line remix-icon mr-2"></i>
                                                Add Request
                                            </b-button>

                                            <b-link
                                                v-if="reservation.token"
                                                variant="primary"
                                                class="btn btn-info"
                                                style="float: left"
                                                target="_blank"
                                                :href="'/epayment/housing/'+reservation.token"
                                            >
                                                Make Payment
                                            </b-link>

                                        </b-col>
                                    </b-row>
                                    <span class="badge badge-info" style="position: absolute;right:-3px;bottom:-3px">{{reservation.created_at}}</span>
                                </b-card>
                            </b-col>
                        </b-row>
                    </div>
                </div>
            <CommonModal ref="requestFormModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    {{ $t('request_form').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <request-form :formId="formId"  :reservationData=formData @requestFormSuccess="requestFormSuccess"
                                  v-if="formProcess == 'request'"/>
                </template>
            </CommonModal>
            </component>
    </div>

</template>

<script>
import TenantAppLayout from "@/layouts/TenantAppLayout.vue";
import RequestForm from "@/modules/myReservationList/pages/RequestForm.vue";
import HousingReservationsListService from "@/services/HousingReservationsListService";
import moment from 'moment';


export default {
    components: {
        RequestForm,
        TenantAppLayout
    },
    metaInfo() {
        return {
            title: this.$t('my_room_reservations')
        }
    },
    data() {
        return {
            visibleKeys: [
                "date_range",
                "building_information",
                "payment_information",
                "status_name",
                "airport_transfer"
            ],
            tenantUser:{},
            datatable: {
                isLoading: false,
                rows: [],
                total: 0,
                showTable: true,
                queryParams: {
                    filter: {},
                    sort: 'id',
                    page: 1,
                    limit: 20
                }
            },
            formLoading: false,
            formId: null,
            formProcess: null,
        }
    },
    created() {
        this.getRows();
    },
    mounted() {
        if (this.isTenant) {
            this.tenantUserInformation()
        }
    },
    computed: {
        isTenant() {
            return !!localStorage.getItem("tenant_user")
        },
        isStudent() {
          return this.$store.getters["auth/isStudent"];
        },

    },
    methods: {
        requestButtonRules(row){
            const formatedDate = moment(new Date()).format('YYYY-MM-DD');
            const checkOutDate = moment(row.check_out_date, 'MM/DD/YYYY').format('YYYY-MM-DD');
            return (checkOutDate >= formatedDate) && row.status === 'paid';
        },
        getIconClass(field,value) {
            let icons = {
                building_information: "ri-hotel-line",
                payment_information: "ri-money-dollar-box-line",
                date_range:'ri-calendar-check-line',
                airport_transfer: 'ri-flight-land-line'
            };
            if (value === 'Agreement Form Signed') {
                icons.status_name='ri-file-edit-line'
            } else if (value === 'Approved') {
                icons.status_name='ri-checkbox-circle-line'
            } else if (value === 'Declined') {
                icons.status_name='ri-close-circle-line'
            } else if (value === 'Deposit Paid') {
                icons.status_name='ri-bank-card-line'
            } else if (value === 'Paid') {
               icons.status_name='ri-check-double-line'
            } else if (value === 'Pending') {
                icons.status_name='ri-time-line'
            }
            return icons[field] || "ri-information-line";
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type === 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        async tenantUserInformation() {
            const tenantUsers =localStorage.getItem('tenant_user')?JSON.parse(localStorage.getItem('tenant_user')):{}
            this.tenantUser=tenantUsers

        },
        async getRows() {
            return HousingReservationsListService.getAll({},this.isTenant)
                .then((response) => {
                    this.datatable.rows = response.data.data.map((item)=>{
                        let airport_transfer=null;
                        if(item.airport_transfer){
                            airport_transfer="Created on: "+item.airport_transfer.created_on+" Status: "+item.airport_transfer.status;
                            if(item.airport_transfer.payment_date){
                                airport_transfer += " Payment Date: "+item.airport_transfer.payment_date;
                            }
                        }
                        return {
                            ...item,
                            date_range:item.check_in_date + ' - ' +item.check_out_date,
                            building_information:item.building_name+' / '  +item.room_name +' : '+item.bed_name+' - '+item.bed_type_name ,
                            payment_information:this.currencyFormat(item.total_amount,0) + ' (' +this.currencyFormat(item.fee,0)+' per month) Paid: '+this.currencyFormat(item.total_paid_amount,0)+'  Balance: '+this.currencyFormat(item.balance,0),
                            airport_transfer: airport_transfer
                        }
                    });
                    this.datatable.total = response.data.pagination.total;
                })
                .catch((e) => {
                  if (e.response.status===401){
                    this.$router.push('/housing/tenant/login');
                    localStorage.getItem('tenant_user')?? localStorage.removeItem('tenant_user');
                    localStorage.getItem('token')?? localStorage.removeItem('tenant_user');
                  }
                    this.showErrors(e);
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        formClear() {
            this.formId = null
            this.formProcess = null
        },
        requestFormShow(row) {
            this.formId = row.id
            this.formData=row
            this.formProcess = 'request'
            this.$refs.requestFormModal.$refs.commonModal.show()
        },
        requestFormSuccess() {
            this.$refs.requestFormModal.$refs.commonModal.hide()
            this.getRows()
            this.formClear()
        },
    }

}


</script>
<style scoped>
.remix-icon {
    font-size: 20px;
    color: #007bff;
}
</style>
