<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <!-- Length of Hold -->
<!--                <b-col cols="12" class="mb-2">-->
<!--                    <ValidationProvider name="length_of_hold" rules="required" v-slot="{ errors }">-->
<!--                        <b-form-group :label="$t('length_of_hold')">-->
<!--                            <b-form-input-->
<!--                                type="number"-->
<!--                                v-model="request_data.length_of_hold"-->
<!--                                :state="errors.length === 0"-->
<!--                            />-->
<!--                            <b-form-invalid-feedback v-if="errors.length">{{ errors[0] }}</b-form-invalid-feedback>-->
<!--                        </b-form-group>-->
<!--                    </ValidationProvider>-->
<!--                </b-col>-->

                <!-- Start Date / End Date -->
                <b-col cols="12">
                    <ValidationProvider name="date_range" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('start_date') + ' / ' + $t('end_date')">
                            <v-date-picker
                                v-model="request_data.date"
                                is-range
                                :locale="'en'"
                                :model-config="{ type: 'string', mask: 'MM/DD/YYYY' }"
                                :state="errors.length === 0"
                            >
                                <template v-slot="{ inputValue, inputEvents }">
                                    <div class="border rounded-sm p-1 p-md-3 d-flex align-items-center">
                                        <div class="flex-grow-1">
                                            <b-form-group class="m-0 mr-1">
                                                <input
                                                    :value="inputValue.start"
                                                    v-on="inputEvents.start"
                                                    :placeholder="$t('start_date')"
                                                    class="form-control"
                                                />
                                            </b-form-group>
                                        </div>
                                        <div class="flex-grow-1">
                                            <b-form-group class="m-0 ml-1">
                                                <input
                                                    :value="inputValue.end"
                                                    v-on="inputEvents.end"
                                                    :placeholder="$t('end_date')"
                                                    class="form-control"
                                                />
                                            </b-form-group>
                                        </div>
                                    </div>
                                </template>
                            </v-date-picker>
                            <b-form-invalid-feedback v-if="errors.length">{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>

                <!-- File Upload -->
                <b-col cols="12">
                    <b-form-group :label="$t('file')">
                        <div class="d-flex custom-file-upload">
                            <b-form-file
                                v-model="request_data.file"
                                :placeholder="$t('select_file')"
                                :drop-placeholder="$t('drop_file')"
                                ref="fileInput"
                                multiple
                            ></b-form-file>
                            <b-button variant="outline-secondary" @click="$refs.fileInput.$el.childNodes[0].click();">
                                {{ $t('browse') }}
                            </b-button>
                        </div>
                        <div class="alert alert-info mt-2">{{ $t('warning_file2') }}</div>
                    </b-form-group>
                </b-col>

                <!-- Save Button -->
                <b-col cols="12" class="d-flex justify-content-center align-items-center">
                    <b-form-group class="mb-0">
                        <b-button variant="primary" block @click="approved" :disabled="formLoading">
                            {{ $t('save').toUpperCase() }}
                        </b-button>
                    </b-form-group>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>

<script>
    // Services
    import StudentRequestService from '@/services/StudentRequestService'
    import {EventBus} from "@/main";

    export default {
        props: {
            data:{
                type:Object
            }
        },
        data() {
            return {
                formLoading: false,
                selected: null,
                request_data: {
                    housing_bed_id:this.data.housing_bed_id,
                    length_of_hold: null,
                    start_date: null,
                    end_date: null,
                    file:[]
                }
            }
        },
        computed:{
            getReservationToken(){
                    return this.$store.getters['roomReservationSystem/getReservationToken']
                }
        },
        mounted() {
          EventBus.$on('approvedRequestForm',(event)=>{
               if (event.constantCode==='housing_request.bed_hold_request_text'){
                   this.save()
               }
          })
        },
        methods: {
           async approved(){
                const isValid = await this.$refs.formModalValidate.validate();
                if (!isValid) {
                    return
                }
                EventBus.$emit('bed_hold_request',{
                    constant:'housing_request.bed_hold_request_text'
                    }
                )
            },
            async save() {
            const isValid = await this.$refs.formModalValidate.validate();
             if (isValid) {
                 if (this.request_data.date){
                     this.request_data.start_date= this.request_data.date.start
                     this.request_data.end_date= this.request_data.date.end
                     delete this.request_data.date
                 }
                 const config = {
                     headers: {
                         Authorization: `Bearer ${this.getReservationToken}`,
                         'Content-Type': 'multipart/form-data'
                     },

                 };


                 const formData = new FormData();
                 formData.append('request_data[start_date]', this.request_data.start_date);
                 formData.append('request_data[end_date]', this.request_data.end_date);

                 if (this.request_data.file && this.request_data.file.length > 0) {
                     for (let i = 0; i < this.request_data.file.length; i++) {
                         formData.append(`request_data[files][${i}]`, this.request_data.file[i]);
                     }
                 }

                 formData.append('request_type', 'bed_hold_request');
                 formData.append('housing_reservation_id', this.data.id);


            StudentRequestService.studentRequest(formData,config)
                .then(response => {
                    this.$toast.success(this.$t('api.' + response.data.message));
                    this.$router.push('/housing/request/list');
                })
                .catch(e => {
                    EventBus.$emit('formClearRequest')

                    this.showErrors(e, this.$refs.formModalValidate);
                })
           }
          }
        }
    }
</script>
