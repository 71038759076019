<template>
    <div class="scrolls">
            <ValidationObserver ref="formModalValidatess">
                <b-row>
                    <!-- Reason Input -->
                    <b-col cols="12">
                        <ValidationProvider name="reason" rules="required" v-slot="{ errors }">
                            <b-form-group :label="$t('reason')">
                                <b-form-textarea
                                    id="textarea"
                                    v-model="request_data.reason"
                                    placeholder="Enter Reason"
                                    rows="6"
                                    max-rows="12"
                                    :state="!errors.length"
                                ></b-form-textarea>
                                <b-form-invalid-feedback v-if="errors.length">{{ errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>

                    <!-- File Upload Input -->
                    <b-col md="12">
                        <ValidationProvider name="file" rules="" v-slot="{ errors }">
                            <b-form-group :label="$t('file')">
                                <div class="d-flex custom-file-upload">
                                    <b-form-file
                                        v-model="request_data.file"
                                        :state="errors.length === 0"
                                        :placeholder="$t('select_file')"
                                        :drop-placeholder="$t('drop_file')"
                                        ref="fileInput"
                                        multiple
                                    ></b-form-file>
                                    <b-button
                                        variant="outline-secondary"
                                        @click="$refs.fileInput.$el.childNodes[0].click();"
                                    >
                                        {{ $t('browse') }}
                                    </b-button>
                                </div>
                                <b-form-invalid-feedback v-if="errors.length">{{ errors[0] }}</b-form-invalid-feedback>
                                <div class="alert alert-info mt-2">{{ $t('warning_file2') }}</div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>


                    <b-col cols="12">
                        <datatable-filter v-show="datatable.filterStatus" @filter="filter" :clear-button="false">
                            <ValidationObserver ref="formModalValidate">
                                <!-- Date Range Filter -->
                                <b-col cols="12" class="p-3">
                                    <b-form-group label="When will you move into the new room?">
                                        <ValidationProvider name="date" rules="required" v-slot="{ valid, errors }">
                                            <select-date v-model="datatable.queryParams.filter.check_in_date"></select-date>
                                        </ValidationProvider>
                                    </b-form-group>
                                </b-col>

                                <!-- Room Type Filter -->
                                <b-col cols="12">
                                    <ValidationProvider name="room_type" rules="" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('room_type')">
                                            <parameter-selectbox :public="true" code="housing_room_types" v-model="datatable.queryParams.filter.room_type" :validate-error="errors[0]" />
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>

                            </ValidationObserver>
                        </datatable-filter>
                    </b-col>

                    <!-- Datatable -->
                    <b-col cols="12">
                        <datatable :isLoading.sync="datatable.isLoading"
                                   :columns="datatable.columns"
                                   :rows="datatable.rows"
                                   :total="datatable.total"
                                   :queryParams="datatable.queryParams"
                                   @on-page-change="onPageChange"
                                   @on-sort-change="onSortChange"
                                   @on-per-page-change="onPerPageChange"
                                   v-show="datatable.showTable"
                                   class="datatable-responsive" />
                    </b-col>

                    <b-col cols="12" v-if="selected">
                        <div class="alert alert-info mt-3">
                            <b>Building:</b> {{selected.housing_building_name}} <b>Room:</b> {{selected.housing_room_name}} - {{selected.room_type_name}} <b>Room Change Fee:</b> {{selected.room_change_fee}} <b>Difference:</b> {{selected.difference}}
                        </div>
                    </b-col>
                    <b-col cols="12" v-if="selected">
                        <b-button variant="primary" block @click="approved">
                            {{ $t('save').toUpperCase() }}
                        </b-button>
                    </b-col>
                </b-row>
            </ValidationObserver>
    </div>
</template>

<script>

// Services
import StudentRequestService from '@/services/StudentRequestService'
import qs from "qs";
import HousingReservationFormService from "@/services/HousingReservationFormService";
import {EventBus} from "@/main";


// Other

export default {
    components: {},
    props: {
        data:{
            type:Object
        },
    },
    created() {
        this.datatable.queryParams.filter.check_out_date=this.data.check_out_date
        this.datatable.queryParams.filter.gender=this.data.gender
        this.datatable.queryParams.reservation_id=this.data.id
    },
    data() {
        return {
            selected: null,
            request_data: {
                housing_bed_id:this.data.housing_bed_id,
                reason: null,
                file:[]
            },
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: "Select this bed",
                                class: 'ri-calendar-check-line',
                                hidden: false,
                                callback: (row) => {
                                    this.selected=row;
                                }
                            }
                        ]
                    },
                    {
                        label: this.$t('building'),
                        field: 'housing_building_name',
                        sortable: false
                    },
                    {
                        label: this.$t('room'),
                        field: 'housing_room_name',
                        sortable: false
                    },
                    {
                        label: this.$t('floor'),
                        field: 'housing_floor',
                        sortable: false
                    },
                    {
                        label: this.$t('room_type'),
                        field: 'room_type_name',
                        sortable: false
                    },
                    {
                        label: this.$t('bed_type'),
                        field: 'type_name',
                        sortable: false
                    },
                    {
                        label: this.$t('bed'),
                        field: 'bed_name',
                        sortable: false
                    },
                    {
                        label: this.$t('monthly_fee'),
                        field: 'fee',
                        sortable: false,
                        formatFn: (row) => {
                            return  this.currencyFormat(row)
                        }
                    },
                    {
                        label: this.$t('difference'),
                        field: 'difference',
                        sortable: false,
                        formatFn: (row) => {
                            return  this.currencyFormat(row)
                        }
                    },
                    {
                        label: this.$t('room_change_fee'),
                        field: 'room_change_fee',
                        sortable: false,
                        formatFn: (row) => {
                            return  this.currencyFormat(row)
                        }
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {
                        check_in_date: null,
                        check_out_date: null,
                        gender: null,
                    },
                    room_change_request: true,
                    reservation_id: null,
                    sort: 'id',
                    page: 1,
                    limit: 10
                }
            },
            formLoading: false,
        }
    },
    computed:{
        getReservationToken(){
            return this.$store.getters['roomReservationSystem/getReservationToken']
        }
    },
    mounted() {
        EventBus.$on('approvedRequestForm',(event)=>{
            if (event.constantCode==='housing_request.room_change_request_text'){
                this.save()
            }
        })

    },
    methods: {
        async approved(){
            const isValid = await this.$refs.formModalValidatess.validate();

            if (!isValid) return;

            EventBus.$emit('room_change_request',{
                constant:'housing_request.room_change_request_text'
            });


        },
        async save() {
            const isValid = await this.$refs.formModalValidatess.validate();
            if (isValid && this.selected) {
                const config = {
                    headers: {
                        Authorization: `Bearer ${this.getReservationToken}`,
                        'Content-Type': 'multipart/form-data'
                    },
                };
                const formData = new FormData();

                formData.append('request_data[reason]', this.request_data.reason);
                formData.append('request_data[check_in_date]', this.datatable.queryParams.filter.check_in_date);
                formData.append('request_data[housing_bed_id]', this.selected.id);
                formData.append('housing_reservation_id',this.data.id);
                formData.append('request_type', 'room_change_request');

                if (this.request_data.file && this.request_data.file.length > 0) {
                    for (let i = 0; i < this.request_data.file.length; i++) {
                        formData.append(`request_data[files][${i}]`, this.request_data.file[i]);
                    }
                }
                StudentRequestService.studentRequest(formData,config)
                    .then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                        this.$router.push('/housing/request/list');
                    })
                    .catch(e => {
                        this.showErrors(e, this.$refs.formModalValidate);
                        EventBus.$emit('formClearRequest')
                    })
            }
        },
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        async getRows() {
            const isValid = await this.$refs.formModalValidate.validate();

            if (isValid) {
                this.datatable.showTable = true
                this.datatable.isLoading = true

                const config = {
                    params: {
                        ...this.datatable.queryParams
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };

                return HousingReservationFormService.getAll(config)
                    .then((response) => {
                        this.datatable.rows = response.data.data;
                        this.datatable.total = response.data.pagination.total;
                    })
                    .catch((e) => {
                        this.showErrors(e);
                    })
                    .finally(() => {
                            this.datatable.isLoading = false;
                        }
                    );
            }
        },
    }
}
</script>
