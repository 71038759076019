<template>
    <div>
            <div class="border rounded p-5 mb-3">
                <ParameterSelectbox code="housing_request_types" v-model="requestType" :public="true" />
            </div>
            <div class="col-12" v-if="requestType">
                <h6>
                    <span v-if="requestType && requestType.text">
                        {{ requestType.text }}
                    </span>
                </h6>
                <div class="border rounded p-5">
                    <component v-if="type" :is="type" :data="reservationData" />
                </div>
            </div>

        <CommonModal  :size="constantCode==='housing_request.services_animal_request_text'?'lg':'md'" ref="warningModal" :onHideOnlyX="true" @bHideModalHeaderClose="formClear()">
            <template v-slot:CommonModalTitle>
                <div>{{$t('warning')}}</div>
            </template>
            <template v-slot:CommonModalContent>
                <div>
                    <WarningModal v-if="formProgress==='Warning'" :constantCode="constantCode"  />
                </div>
            </template>
        </CommonModal>
    </div>
</template>

<script>
    // Pages
    import bedHoldRequest from './pages/student/bedHoldRequest/Index'
    import serviceAnimal from './pages/student/serviceAnimal/Index'
    import roomChangeRequest from './pages/student/roomChangeRequest'
    import workOrderRequest from './pages/student/workOrderRequest/Index'
    import initialComplaint from './pages/student/initialComplaint/Index'
    import religiousExemption from './pages/student/religiousExemption/Index'
    import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
    import WarningModal from "./WarningModal.vue"
    import airportTransferRequest from "./pages/student/airportTransferRequest/Index.vue"
    import {EventBus} from "@/main";

    export default {
        props:{
          reservationData:{
              type: Object,
          }
        },
        components: {
            WarningModal,
            bedHoldRequest,
            serviceAnimal,
            roomChangeRequest,
            workOrderRequest,
            initialComplaint,
            ParameterSelectbox,
            religiousExemption,
            airportTransferRequest
        },
        data() {
            return {
                formProgress:null,
                constantCode:null,
                requestType: null
            }
        },
        mounted() {
            EventBus.$on('bed_hold_request',(event)=>{
                this.constantCode=event.constant
                this.showWarningModal()
            })
            EventBus.$on('room_change_request',(event)=>{
                this.constantCode=event.constant
                this.showWarningModal()
            })
            EventBus.$on('services_animal',(event)=>{
                this.constantCode=event.constant
                this.showWarningModal()
            })
            EventBus.$on('formClearRequest',()=>{
                this.formClear()
            })
        },
        computed: {
            type(){
                if (this.requestType){
                    return  this.requestType?.replaceAll('_','-')
                }
                return this.requestType
            }

        },
        methods:{
            formClear(){
              this.formProgress=null
                this.$refs.warningModal.$refs.commonModal.hide()
            },
            showWarningModal(){
                this.formProgress='Warning'
                this.$refs.warningModal.$refs.commonModal.show()
            }
        }
    }
</script>
