var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"header fixed-top"},[_c('b-navbar',{staticClass:"p-0"},[_c('b-navbar-brand',{staticClass:"p-0 m-0 d-flex align-items-center collapsed-logo desktop-logo",class:[{ 'expanded-logo': _vm.sideBarExpandedWrapper }]},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hideLogo),expression:"!hideLogo"}],staticClass:"logo-wrapper"},[_c('img',{attrs:{"src":require("../../assets/img/island-candos-logo.png"),"alt":"Logo","height":"60"}})])]),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-collapse',{staticClass:"d-block",attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',{staticClass:"ml-0 ml-lg-auto pl-4 pl-lg-0 py-4 pr-4 py-lg-5 pr-lg-5"},[_c('div',{staticClass:"d-flex"},[_c('b-button',{staticClass:"btn-40 d-block d-lg-none",attrs:{"variant":"outline-secondary"},on:{"click":_vm.changeSideBarExpandedWrapper}},[_c('i',{staticClass:"ri-menu-2-line"})]),_c('div',{staticClass:"custom-container"},[_c('div',{staticClass:"custom-box"},[_c('span',[_vm._v("Technical Support")]),_c('a',{staticClass:"d-block",attrs:{"href":"mailto:mybau@bau.edu"}},[_vm._v(" mybau@bau.edu ")])])]),_c('div',{staticClass:"p-1 rounded mr-2 d-none d-md-flex justify-content-center",staticStyle:{"border":"1px solid #e9ecef !important"}},[_vm._v(" "+_vm._s(_vm.user.name)+" "+_vm._s(_vm.user.surname)+" ")])],1),_c('div',{staticClass:"d-flex"},[_c('b-dropdown',{ref:"dropdown",staticClass:"no-btn",attrs:{"id":"dropdown-form","variant":"link","right":"","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [(_vm.photo)?_c('div',{staticClass:"wh-40-img rounded bg-cover-center",style:({
                                        'background-image':
                                            'url(' + _vm.photo + ')',
                                    })}):_c('div',{staticClass:"wh-40-img rounded bg-cover-center",style:({
                                        'background-image':
                                            'url(' +
                                            require('@/assets/img/no-photo-2.jpg') +
                                            ')',
                                    })})]},proxy:true}])},[_c('b-dropdown-item',{on:{"click":_vm.logoutStudent}},[_vm._v("Logout")])],1)],1)])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }