<template>
    <div>
        <div>
            <b-alert :show="alertShow" variant="warning">
                <p>{{ message }}</p>
            </b-alert>
        </div>
        <ValidationObserver ref="formModalValidate">
            <ValidationProvider name="status" rules="required" v-slot="{valid, errors}">
                <div class="mr-3">
                    <b-form-checkbox
                        id="checkbox-1"
                        v-model="checkbox"
                        name="checkbox-1"
                        value="accepted"
                        :unchecked-value="null"
                    >
                        I have read, understood, and accept
                    </b-form-checkbox>
                    <b-form-invalid-feedback v-if="errors[0]"
                                             v-html="errors[0]">

                    </b-form-invalid-feedback>
                </div>
            </ValidationProvider>
            <div class="d-flex justify-content-center  align-center mt-3 mb-3">
                <b-button @click="approved" variant="primary" class="mr-3">{{
                        $t('approved')
                    }}
                </b-button>
            </div>
        </ValidationObserver>

    </div>


</template>
<script>

import ConstantService from "@/services/ConstantService";
import {EventBus} from '@/main'


export default {
    props: {
        constantCode: {
            type: String,
        }
    },
    data() {
        return {
            formLoading: false,
            checkbox: null,
            message: null,
            alertShow: false,
        }
    },
    created() {
        this.messages(this.constantCode)
    },
    methods: {
        messages(constantCode) {
            const config = {
                params: {
                    key: constantCode
                },
            };
            ConstantService.getValue(config)
                .then(response => {
                    if (response.data.data != null) {
                        this.message = response.data.data
                        this.alertShow = true
                    } else {
                        this.alertShow = false
                    }
                }).catch(err => {
                this.showErrors(err)
            })
        },
        async approved() {
            const isValid = await this.$refs.formModalValidate.validate()
            if (!isValid) return
            this.formLoading=true
            EventBus.$emit('approvedRequestForm',{
                constantCode:this.constantCode,
            })
            this.formLoading=false
        }
    }
}


</script>

<style>

</style>
