<template>
    <header class="header fixed-top">
        <b-navbar class="p-0" >
            <b-navbar-brand
                class="p-0 m-0 d-flex align-items-center collapsed-logo desktop-logo"
                :class="[{ 'expanded-logo': sideBarExpandedWrapper }]"
            >
                <div class="logo-wrapper"  v-show="!hideLogo">
                    <img src="../../assets/img/island-candos-logo.png" alt="Logo" height="60" />
                </div>
            </b-navbar-brand>
            <b-collapse id="nav-collapse" is-nav>
                <b-collapse id="nav-collapse" is-nav class="d-block">
                    <b-navbar-nav
                        class="ml-0 ml-lg-auto pl-4 pl-lg-0 py-4 pr-4 py-lg-5 pr-lg-5"
                    >

                        <div class="d-flex">
                            <b-button
                                class="btn-40 d-block d-lg-none"
                                variant="outline-secondary"
                                @click="changeSideBarExpandedWrapper"
                            >
                                <i class="ri-menu-2-line"></i>
                            </b-button>
                            <div class="custom-container">
                                <div class="custom-box">
                                    <span>Technical Support</span>
                                    <a href="mailto:mybau@bau.edu" class="d-block">
                                        mybau@bau.edu
                                    </a>
                                </div>
                            </div>

                            <div style=" border: 1px solid #e9ecef !important;" class="p-1 rounded mr-2 d-none d-md-flex justify-content-center">
                                {{ user.name }} {{ user.surname }}
                            </div>
                        </div>


                        <div class="d-flex">

                            <b-dropdown
                                id="dropdown-form"
                                ref="dropdown"
                                variant="link"
                                class="no-btn"
                                right
                                no-caret
                            >
                                <template #button-content>
                                    <div
                                        class="wh-40-img rounded bg-cover-center"
                                        :style="{
                                            'background-image':
                                                'url(' + photo + ')',
                                        }"
                                        v-if="photo"
                                    ></div>
                                    <div
                                        class="wh-40-img rounded bg-cover-center"
                                        v-bind:style="{
                                            'background-image':
                                                'url(' +
                                                require('@/assets/img/no-photo-2.jpg') +
                                                ')',
                                        }"
                                        v-else
                                    ></div>
                                </template>

                                <b-dropdown-item @click="logoutStudent">Logout</b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </b-navbar-nav>
                </b-collapse>
            </b-collapse>
        </b-navbar>
    </header>
</template>
<script>

// Other
import { localeChanged } from "vee-validate";


export default {
    components: {
    },
    props: {
        selectedMenu: {},
        title: {
            type: String,
            default: null,
        },
        isNewButton: {
            type: Boolean,
            default: false,
        },
        isRankButton: {
            type: Boolean,
            default: false,
        },
        isImportButton: {
            type: Boolean,
            default: false,
        },
        isColumns: {
            type: Boolean,
            default: false,
        },
        isFilter: {
            type: Boolean,
            default: true,
        },
        isExpandAll: {
            type: Boolean,
            default: false,
        },
        isCollapseAll: {
            type: Boolean,
            default: false,
        },
        isCancelToMove: {
            type: Boolean,
            default: false,
        },
        actions: {
            type: Array,
            default: null,
        },
        isBackButton: {
            type: Boolean,
            default: false,
        },
        isPrint: {
            type: Boolean,
            default: false,
        },
        backButton: {
            type: Function,
            default: function () {},
        },
    },
    data() {
        return {
            photo: null,
            hideLogo:false,

            // Filter
            filterDivStatus: true,

            // Form Process
            formProcess: null,
        };
    },
    computed: {
        backVisibleState() {
            if (this.$route.path.includes("dashboard")) {
                return false
            }
            return true
        },
        // Locale
        locale() {
            return this.$store.getters["getLocale"];
        },

        // Notifications
        notifications() {
            return this.$store.getters["auth/getNotifications"];
        },

        // Layout
        sideBarExpandedWrapper() {
            return this.$store.getters.getAppLayoutSideBarExpandedWrapper;
        },
        nightMode() {
            return this.$store.getters.getNightMode;
        },

        // User
        user() {
            return localStorage.getItem('tenant_user') ? JSON.parse(localStorage.getItem('tenant_user')) : {}
        },

        // Photo
        /*photo() {
            let photo = this.$store.getters["auth/getPhoto"];
            if (photo) {
                return "'" + photo + "'";
            }
            return null;
        },*/

        // Filtered Actions
        filteredActions() {
            let arr = [];
            this.actions.forEach((item) => {
                let add = false;
                if (
                    !item.permission ||
                    (item.permission && this.checkPermission(item.permission))
                ) {
                    add = true;
                }
                if (add && typeof item.show == "function") {
                    add = item.show();
                }
                if (add) {
                    arr.push(item);
                }
            });
            return arr;
        },
    },
    methods: {
        // Template
        changeLocale(locale) {
            this.$i18n.locale = locale;
            this.$store.commit("setLocale", locale);
            localeChanged();
        },
        changeNightMode() {
            this.$store.commit("changeNightMode");
        },
        changeSideBarExpandedWrapper() {
            this.$store.commit("changeAppLayoutSidebarExpandedWrapper");
        },

        // Print
        async print() {
            const options = {
                autoClose: false,
            };
            await this.$htmlToPaper("print-content",options);
        },
        logoutStudent() {
            localStorage.removeItem('tenant_token');
            localStorage.removeItem('tenant_user');
            this.$router.push('/housing/tenant/login');
        },
    },
    mounted() {
    },
};
</script>


<style lang="scss">
.header-back-icon {
    font-size: 2rem;
    cursor: pointer;
}
@media screen and (min-width: 768px) {
    .header-user-info-btn {
        line-height: normal !important;
    }
}
.custom-container {
    display: flex;
    align-items: center;
    margin-right: 1em;
    justify-content: center;
    font-size: 12px;
}
header.header .navbar-brand{
    height: 104px;
}

header.header .collapsed-logo .logo-wrapper{
    width: 80px;
    height: 60px;
}

header.header .navbar-brand{
    padding: 0 !important;
}

.custom-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #e9ecef !important;
    padding: 0.5rem;
    border-radius: 4px;
}
@media (max-width: 576px) {
    .custom-container {
        margin-left: 150px;
        font-size: 10px;
    }
}
.text-style{
    font-size: 13px;
}
</style>
